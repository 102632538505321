.App {
  text-align: center;
  background-color: #123677;
}

html {
  scroll-behavior: smooth;
}


